@import "~bulma/sass/utilities/_all";

.estimate-row {
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover, &:active {
    background-color: $grey-lighter;
  }
}

.folder-icon {
  transition: opacity 0.3s ease;
}

.folder-closed {
  opacity: 1;
  color: #444;
}

.folder-open {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  color: #111;
}

.estimate-row:hover .folder-closed,
.estimate-row:active .folder-closed {
  opacity: 0;
}

.estimate-row:hover .folder-open,
.estimate-row:active .folder-open {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .estimate-row td {
    padding: 0.5rem;
  }

  .buttons {
    flex-wrap: nowrap;
  }

  .button {
    padding: 0.5rem;
  }
}
