@font-face {
  font-family: 'Mont';
  src: url('./Mont-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mont';
  src: url('./Mont-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mont';
  src: url('./Mont-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
