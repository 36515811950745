.folder-icon-container {
  position: relative;
  display: inline-block;
  
  .folder-icon {
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .folder-closed {
    opacity: 1;
    color: #4a4a4a; // Domyślny kolor tekstu Bulmy, możesz dostosować jeśli potrzebujesz
  }

  .folder-open {
    opacity: 0;
  }
}

tr:hover {
  .folder-icon-container {
    .folder-closed {
      opacity: 0;
    }

    .folder-open {
      opacity: 1;
    }
  }
}

// Zapewniamy, że otwarta ikona folderu ma zawsze kolor primary
.folder-icon.folder-open.has-text-primary {
  color: var(--primary-color, #00d1b2); // Użyj zmiennej CSS lub domyślnego koloru Bulmy
}
