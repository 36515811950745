.logo-container {
  width: 300px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; // Zapobiega wyświetlaniu się poza kontenerem

  .link { // zmiana z 'a' na klasę '.link', którą dodamy do komponentu <Link>
    display: block;
    width: 100%;
    height: 100%;

    img.logo-image {
      width: 100%;
      height: 100%;
      object-fit: contain; // Zapewnia, że logo wypełnia kontener bez przekraczania i bez deformacji
      object-position: center; // Centruje obraz w kontenerze
    }
  }
}
