//not empty
 
.search-container {
  .control {
    &.has-icons-right {
      .icon.is-right {
        pointer-events: all;
        cursor: pointer;
      }
    }
  }

  .clear-search {
    color: #4a4a4a;
    transition: color 0.2s ease;

    &:hover {
      color: #363636;
    }
  }
  
  margin-bottom: 2rem;
  
  .search-input {
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    
    &:focus {
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    }
  }
  
  .search-icon {
    color: #4a4a4a;
  }
}
