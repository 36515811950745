@import "~bulma/bulma";

.cardStandard {
  &__image {
    position: relative;
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;

    &--checked {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: hsl(142, 65%, 57%);
      border-radius: 50%;
      border: 2px solid white;
    }
  }

  &__title {
    color: red;
  }

  &__total {
    font-size: 1.2em;
  }

  &__description-product {
    text-align: center;
    margin-top: -8px;
  }
}

.is-archived {
  opacity: 1;
}

.is-archived input:disabled,
.is-archived select:disabled,
.is-archived button:disabled,
.is-archived .is-disabled {
  // cursor: not-allowed;
  opacity: 0.7;
}

.cardStandard.is-inactive {
  opacity: 0.8;
  // border: 2px solid #3298dc; /* niebieski kolor dla oznaczenia */
}

.custom-columns-margin {
  margin-bottom: -1rem !important; // Możesz dostosować tę wartość
}
