.addProductForm {
  &__label-center {
    justify-content: center;
  }

  &__currency {
    padding-right: 2em;
  }

  &__right-icon {
    color: hsl(0, 0%, 66%);
    height: 2.5em;
    pointer-events: none;
    position: absolute;
    top: 0.5em;
    right: 0;
    width: 3.7em;
  }

  &__image {
    object-fit: cover;
  }
}
