.user-product-tabs-dropdown-container {
  display: flex;
  justify-content: center;
  width: 100%;

  .dropdown {
    width: 250px;

    &.is-active {
      .dropdown-menu {
        display: block;
      }
    }

    .dropdown-trigger {
      width: 100%;
    }

    .dropdown-menu {
      width: 100%;
      display: none;
    }
  }
}

.user-product-tabs-dropdown-button {
  width: 100%;
  justify-content: space-between;
  // font-size: 1.1rem;
  // padding: 0.7em 1em;
  font-weight: 600;
  // text-transform: uppercase;
}

.user-product-tabs-dropdown-item {
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

@media screen and (max-width: 768px) {
  .user-product-tabs-dropdown-container .dropdown {
    width: 90%;
  }
}
