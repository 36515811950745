.loginPage {
  background: url('../../assets/images/bg.webp') no-repeat center center;
  background-size: cover;
  min-height: 100vh; /* upewnij się, że zajmuje całą wysokość */
  min-width: 100vw; /* i całą szerokość */
  background-position: top center;
  display: flex;
  justify-content: center;

  &__welcome {
    margin-top: 25px;
  }

  &__content {
    align-self: center;
    width: 370px;
  }
}
