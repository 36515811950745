@import "~bulma/sass/utilities/_all";

.has-background-gradient-blue {
  background: linear-gradient(135deg, #1e57b3, #1e88e5);
}

.has-background-gradient-green {
  background: linear-gradient(135deg, hsl(171, 100%, 41%), hsl(171, 99%, 35%));
}

.has-background-gradient-grey {
  background: radial-gradient(#fafafa, #ffffff);
}

.estimate {
  border: 1px solid #dddddd;
}

.summary-details {
  .table.is-bordered {
    border: none;

    td,
    th {
      border: none;
    }
  }

  .categoryTitle {
    a {
      transition: color 0.1s ease;

      &:hover {
        color: $info;

        .icon, 
        .icon i {
          color: $info !important; // Użycie !important jako ostateczność
        }
      }

      // Dodajemy styl dla ikony, gdy nie ma :hover
      .icon, 
      .icon i {
        transition: color 0.1s ease;
      }
    }
  }

  .product-list-container {
    border: 1px solid #dbdbdb; // kolor bordera, możesz dostosować
    border-radius: 8px; // zaokrąglenie rogów, możesz dostosować
    overflow: hidden; // zapewnia, że zawartość nie wychodzi poza zaokrąglone rogi
    padding: 0;
    margin: 0;

    // Usuwa margines z pierwszego i ostatniego elementu listy
    > :first-child {
      margin-top: 0;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }
}