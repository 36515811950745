@import 'bulma/bulma';

.header {
  padding: 10px;

  &__logo {
    display: flex;
    justify-content: center;
    flex-grow: 2;
  }
  &__logo-motto {
    font-weight: 600;
    font-size: 0.8em;
    color: $info;
  }
  &__center {
    display: flex;
    justify-content: center;

    &--mobile {
      display: flex;
      align-items: center;
    }
  }
  &__right {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__logo-image {
    height: 75px;
    width: 202px;
  }
}

@media screen and (max-width: 768px) {
  .button-mobile-icon-only {
    padding-right: 0.75em !important;
    .icon:last-child:not(:first-child) {
      margin-right: 0 !important;
    }
  }
}

.header__right-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
}