.homepage {
  &__logo {
    width: 90%;
  }

  &__custom-separator {
    height: 1px;
    background-image: linear-gradient(to right, transparent, #dbdbdb, transparent);
    margin: 0 0 1.5rem 0;
  }
}

