.products-table {
  border: 1px solid #dbdbdb;
  
  thead {
    background-color: #f5f5f5;
    
    th {
      border-bottom: 1px solid #dbdbdb !important;
      font-weight: 400;
      text-align: left !important;
    }
  }

  th, td {
    border: 1px solid #dbdbdb;
    vertical-align: middle !important;
    padding: 5px !important;
    text-align: left !important;
  }

  td:first-child,
  td:last-child {
    text-align: left !important;
  }
  
  tr {
    &:hover {
      &.has-background-success-light {
        background-color: #ebfbee !important;  // lub jaki tam kolor używasz dla success-light
      }
      &.has-background-info-light {
        background-color: #ebf5fb !important;  // lub jaki tam kolor używasz dla info-light
      }
      &:not(.has-background-success-light):not(.has-background-info-light) {
        background-color: inherit !important;
      }
    }
  }

  &.is-fullwidth {
    th {
      &:nth-child(1) { width: 25%; }  // Nazwa produktu
      &:nth-child(2) { width: 8%; }  // Ilość
      &:nth-child(3) { width: 10%; }  // Cena sprzedaży
      &:nth-child(4) { width: 10%; }  // Cena zakupu
      &:nth-child(5) { width: 18%; }  // Status
      &:nth-child(6) { width: 29%; }  // Notatki
    }
  }
}

// Nowe style dla komórek notatek
.notes-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 2.5em;
  cursor: pointer;
  padding: 0.25rem;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: #f5f5f5;
  }

  .notes-content {
    flex-grow: 1;
    margin-right: 0.5rem;
  }

  .notes-text {
    display: block;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .button.is-ghost {
    &:hover {
      background-color: transparent;
      color: #3273dc;
    }
  }
}

// Style dla statusu
.button.is-light {
  &.has-text-grey-light:hover {
    color: #363636 !important;
  }
  
  &.has-text-warning:hover {
    color: #946c00 !important;
  }
  
  &.has-text-success:hover {
    color: #257942 !important;
  }
  
  .icon {
    margin-right: 0.3rem;
  }
}

.pre-wrap {
  white-space: pre-wrap;
}

tr {
  transition: background-color 0.3s ease;
}
