@import "~bulma/bulma";
// @import "~bulma-extensions";
@import "~@creativebulma/bulma-divider";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  overflow-x: hidden; // Zapobieganie przewijaniu na poziomie dokumentu
  width: 100%;
}

.container {
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  width: 100%;
  overflow-x: hidden; // ew. to usunąć jak coś nie tak
}

.main {
  flex: 1;}

.full-width-page {
  position: relative;
  width: 100%;
  margin: 0;
  
  .section {
    width: 100%;
    max-width: 1344px;
    margin: 0 auto;
    padding: 3rem 1.5rem;
  }
}

.content-wrapper {
  width: 100%;
  max-width: 1344px; // lub inna wartość której potrzebujesz
  margin: 0 auto;
  padding: 0 1.5rem; // dodaj padding zamiast używać vw
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}
