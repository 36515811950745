.terms-of-service,
.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;

  h1 {
    font-size: 28px;
    color: #2c3e50;
    margin-bottom: 20px;
    text-align: center;
  }

  section {
    margin-bottom: 30px;

    h2 {
      font-size: 24px;
      color: #34495e;
      margin-top: 30px;
      margin-bottom: 15px;
      border-bottom: 2px solid #ecf0f1;
      padding-bottom: 10px;
    }

    ul {
      list-style-type: none;
      padding-left: 20px;
      margin-bottom: 15px;

      li {
        margin-bottom: 10px;
        position: relative;

        &::before {
          content: "•";
          color: #3498db;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }

        ul {
          margin-top: 10px;
          margin-bottom: 0;

          li {
            margin-bottom: 5px;

            &::before {
              content: "◦";
              color: #2980b9;
            }
          }
        }
      }
    }
  }

  p {
    margin-bottom: 15px;
  }

  a {
    color: #3498db;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: 768px) {
  .terms-of-service,
  .privacy-policy {
    padding: 15px;

    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 20px;
    }

    ul {
      padding-left: 15px;
    }
  }
}
