.summary-container {
  position: relative;
}

.summary-content-web {
  display: block;
}

.summary-content-print {
  display: none;
}

.summary-title {
  margin-top: 0;
  padding-top: 0;
}

.summary-print-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.summary-print-info {
  flex: 1;
}

.summary-print-logo {
  text-align: right;
  flex: 1;
}

@media print {
  .summary-content-web {
    display: none;
  }

  .summary-content-print {
    display: block;
  }

  #companyLogo {
    max-width: 200px;
    height: auto;
  }

  body {
    font-family: Arial, sans-serif;
    font-size: 11pt;
    line-height: 1.3;
    color: #000;
  }

  h2 {
    font-size: 18pt;
    margin-top: 0;
    padding-top: 0;
  }

  h3 {
    font-size: 14pt;
    margin-top: 5mm;
    margin-bottom: 3mm;
  }

  h4 {
    font-size: 13pt;
    margin-top: 4mm;
    margin-bottom: 2mm;
  }

  ul {
    margin-left: 5mm;
    padding-left: 5mm;
  }

  li {
    margin-bottom: 2mm;
  }

  .no-print {
    display: none !important;
  }

  .summary-print-details {
    width: 100%;
  }
}
