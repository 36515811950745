.footer {
  width: 100%;
  padding: 3rem 1.5rem !important;
  color: white;
  background-color: black;
  
  .content-wrapper {
    max-width: 1344px;
    margin: 0 auto;
    width: 100%;
  }

  a {
    color: white !important;

    &:hover {
      color: darken(white, 10%) !important;
    }
  }
}