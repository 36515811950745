.files-page {
  .card.files-card {  // zmiana z .files-card na .card.files-card
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: transform 0.2s ease, box-shadow 0.2s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 16px rgba(0,0,0,0.1);
    }

    .card-image {
      padding: 0;
      margin-bottom: 0; // dodaj to
      
      figure {
        margin: 0;
      }
      
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        display: block; // dodaj to
      }
    }

    .card-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .content {
        flex-grow: 1;
        margin-bottom: 1rem;
      }

      .download-button {
        margin-top: auto;
      }
    }
  }

  .files-image.is-3by2 {
    background-color: #f5f5f5;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .files-notification {
    .files-icon-text {
      align-items: center;
      
      .icon {
        margin-right: 0.5rem;
      }
    }
  }

  .files-buttons {
    justify-content: center;
    
    &__button {
      flex: 1;
    }
  }

  .files-features {
    .files-icon-text {
      display: flex;
      align-items: center;
      
      .icon {
        margin-right: 0.5rem;
      }
    }

    .files-benefits-grid {
      .files-benefit-item {
        padding: 0.5rem;
        
        @media screen and (max-width: 768px) {
          text-align: center;
          margin-bottom: 0.5rem;
          
          .files-icon-text {
            justify-content: center;
          }
        }
      }
    }

    .files-shield-icon {
      margin-left: 2rem;
      
      @media screen and (min-width: 769px) {
        transform: scale(1.2);
      }
    }

    .title {
      .files-icon-text {
        display: inline-flex;
        
        @media screen and (max-width: 768px) {
          .icon {
            display: none;
          }
        }
      }
    }
  }
}
