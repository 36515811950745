@import "~bulma/bulma";

.productList {
  &__image {
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
  }

  &__button {
    border: none;
    background-color: transparent;
    color: white;
    cursor: pointer;
  }

  &__active {
    height: 30px;
    background-color: $info;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    color: white;
    text-align: center;
    font-size: 0.9rem;
    padding-top: 3px;

    &--not {
      background-color: rgb(151, 151, 151);
    }
  }
}
