@import "~bulma/sass/utilities/_all";

.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2147483647;
  background-color: $white;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  color: $info;

  &__container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__text {
    margin-bottom: 1rem;
    text-align: center;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
